<template>
	<el-dialog title="导入数据" width="850px" :visible.sync="dialogVisible" :before-close="handleClose">
		<div style="width: 100%;padding-bottom: 20px;">
			<header class="flexFloat head_box">
				<div class="head_li on">
					<i></i>
					<span>上传Excel</span>
				</div>
				<div class="head_li" :class="isTab > 1 ? 'on' : ''">
					<i></i>
					<span>数据预览</span>
				</div>
				<div class="head_li" :class="isTab > 2 ? 'on' : ''">
					<i></i>
					<span>导入数据</span>
				</div>
			</header>
			<div style="height: 36px"></div>

			<article class="preview-box">
				<!-- 上传Excel -->
				<div v-if="isTab==1">
					<div v-if="!isError">
						<div class="prew-tis">
							<div class="pTis-txt flex zhong">
								<span>·</span>
								请使用标准模板准备导入数据，模板中的表头名称不可更改，表头行不能新增或删除
								<el-button size="small" type="primary" style="margin-left: 10px;" @click="downloadBtn">
									<i class="el-icon-download"></i>下载导入模板
								</el-button>
							</div>
							<div class="pTis-txt flex zhong">
								<span>·</span>
								导入文件中仅保留一个工作表（sheet）
							</div>
							<!-- 员工花名册-导入员工提示文案 -->
							<!-- <div class="pTis-txt flex zhong" v-if="type==1">
								<span>·</span>
								导入触发数据校验，手机号码、证件号码、工号任一内容不可重复
							</div> -->
							<!-- 学历学位提示文案 -->
							<!-- <div class="pTis-txt flex zhong" v-if="type==4">
								<span>·</span>
								导入手机号码、姓名、院校、学历完全相同时，判断为重复数据，不导入
							</div> -->
							<!-- 核心成员管理提示文案 历史线路记录提示文案 -->
							<div class="pTis-txt flex zhong" v-if="type==5||type>=7&&type<=13">
								<span>·</span>
								系统不去重，全部导入
							</div>
							<!-- 驾驶员列表提示文案 -->
							<div class="pTis-txt flex zhong" v-if="type==6">
								<span>·</span>
								导入后，更新公交车驾驶员证件信息
							</div>
							<!-- 职称管理提示文案 -->
							<div class="pTis-txt flex zhong" v-if="type==14">
								<span>·</span>
								同上职称名称、等级、类型全部一致时，判断为重复
							</div>
						</div>
						<div class="box1_fg">
							<img src="../image/img/adda.png" />
							<b>上传填好的信息表</b>
							<p>文件后缀名必须为xls 或xlsx （即Excel格式），文件大小不得大于10M，最多支持导入1000条数据</p>
							<div class="flexCenter">
								<ul class="file-list" v-if="isPlan">
									<li v-for="(item,index) in fileData" :key="index">
										<div class="file-i01 flex zhong float">
											<div class="file-name flex zhong">
												<img src="../image/img/a_wjd.png" />
												<span class="slh" :title="item.fileName">{{ item.fileName }}</span>
											</div>
											<div class="file-size" v-if="item.percent < 100">{{ item.fileSize}}
											</div>
											<div class="file-size" v-else>上传成功</div>
										</div>
										<el-progress :percentage="item.percent" color="#2470f8"></el-progress>
									</li>
								</ul>
								<div class="flexCenter" style="padding-top: 10px;width:100%" v-if="!isPlan">
									<p class="flexCenter zhan" style="width:50%;">
										<span class="ptzs shou zhan">
											{{fileData&&fileData.length>0?'重新上传':'上传文件'}}
										</span>
										<input type="file" class="fileInput" id="myFile" @change="upLoadFileChange"
											style="cursor: pointer;" accept=".xls,.xlsx" multiple />
									</p>
								</div>
							</div>
						</div>
					</div>
					<!-- 校验错误 -->
					<div v-if="isError">
						<div class="box1_icon flexFloat">
							<div class="box1_icon_left flexCenter">
								<img src="../image/img/ts.png" />
							</div>
							<div class="box1_you">
								<b><span>正常数据：</span>{{verifyData.successNum?verifyData.successNum:''}}</b>
								<b><span>异常数据：</span>{{verifyData.errorNum?verifyData.errorNum:''}}</b>
							</div>
						</div>
						<div class="box1_yc gun">
							<b>异常提示</b>
							<p v-for="(item,index) in verifyData.infos" :key="index">
								{{item}}
							</p>
						</div>
					</div>
				</div>
				<!-- 模拟数据加载 2024-01-23 -->
				<div v-if="isTab == 0.5">
					<div style="width:80%;margin: 20% auto 0 auto;">
						<el-progress :text-inside="true" text-color="#fff" :stroke-width="26" :percentage="planNum">
						</el-progress>
					</div>
				</div>
				<!-- 预览 -->
				<div v-if="isTab==2">
					<!-- 校验正确 -->
					<div>
						<div class="box2_tis flex">
							<p>
								<span>数据列表：</span>共{{tableData.length}}条数据
							</p>
						</div>
						<div class="ptdivLine"></div>
						<!-- 1.用户管理 -->
						<div class="pttable_bor" v-if="fileType==1">
							<el-table :data="tableData" empty-text="暂无数据" height="350" :header-cell-style="{
									background: '#f5f7fa',
									color: '#333333',
									textAlign: 'center !important',
									height: '48px !important',
									padding: '0  !important'
								}" :cell-style="{
									color: '#666',
									textAlign: 'center !important',
									height: '48px !important',
									padding: '5px 0  !important'
								}" stripe>
								<el-table-column type="index" fixed="left" label="序号" width="55"></el-table-column>
								<el-table-column prop="name" label="姓名" min-width="200"></el-table-column>
								<el-table-column prop="jobNumber" label="工号" min-width="200"></el-table-column>
								<el-table-column prop="companyType" label="公司类型" min-width="150"></el-table-column>
								<el-table-column prop="companyName" label="所属公司" min-width="130"></el-table-column>
								<el-table-column prop="deptName" label="所属部门" min-width="130"></el-table-column>
								<el-table-column prop="roleName" label="角色" min-width="150"></el-table-column>
								<el-table-column prop="account" label="登录账号" min-width="200"></el-table-column>
								<el-table-column prop="phone" label="联系电话" min-width="200"></el-table-column>
								<el-table-column prop="pwd" label="初始密码" min-width="200"></el-table-column>
								<el-table-column prop="status" label="是否启用" min-width="200"></el-table-column>
								<el-table-column prop="remark" label="备注" min-width="200"></el-table-column>
							</el-table>
						</div>
					</div>
				</div>
				<!-- 提交成功 -->
				<div class="box3" v-if="isTab == 3">
					<div class="box3-txt">
						<h4>数据导入完成</h4>
						<p>您已成功导入{{tableData.length}}条数据</p>
					</div>
				</div>
			</article>
		</div>
		<footer slot="footer" class="dialog-footer">
			<el-button v-if="isTab==1&&!isError" @click="handleClose">取 消</el-button>
			<el-button type="primary" plain v-if="isTab==1&&isError||isTab==1&&!oneCk" @click="oneBtn">重新上传</el-button>
			<el-button type="primary" plain v-if="isTab==2" @click="oneBtn">上一步</el-button>
			<el-button type="primary" v-if="isTab==1&&!isError" @click="twoBtn" :disabled="oneCk">下一步</el-button>
			<el-button type="primary" v-if="isTab==1&&isError" @click="twoBtnYl">下一步</el-button>
			<el-button type="primary" v-if="isTab==2" @click="threeBtn">导入数据</el-button>
		</footer>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			fileType: {
				type: Number,
				default: 1
			},
			fileUrl: {
				type: String,
				default: ''
			},
			Daoru_url: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				dialogVisible: true,
				isTab: 1,
				tableData: [],
				/* 1员工花名册-导入员工,2员工花名册-导入执（职）业资格,3员工花名册-导入家庭主要成员,4学历学位,5核心成员管理,6驾驶员列表,7历史线路记录,8投诉记录,9交通违法记录,10交通事故表,11积分管理,12获奖情况,13违规记录,14职称管理 */
				type: 1,
				fileData: [],
				verifyData: {},
				isError: false,
				planNum: 0,
				timerPlan: null,
				timerVerify: null,
				isPlan: false,
				oneCk: true,
				twoCk: false,
				threCk: false,
				tableDataJD: [],
				tableDataZT: []
			};
		},
		mounted() {
			this.type = this.fileType;
		},
		methods: {
			/* 上传文件 */
			upLoadFileChange(e) {
				var that = this;
				that.fileData = [];
				that.isPlan = false;
				var files = document.getElementById('myFile').files;
				// that.loading = that.$loading({
				// 	lock: true,
				// 	text: 'Loading',
				// 	spinner: 'el-icon-loading',
				// 	background: 'rgba(0, 0, 0, 0.7)'
				// });
				var objFile = null; // 创建公用对象
				var file = files[0]; // 获取当前文件
				console.log(file, 'file---');
				var fileSize = '0KB';
				if (file.size > 1024 * 1024 * 10) {
					// that.loading.close();
					that.$message.error('单个文件不能超过10M');
					// 上传完成后清空
					objFile = document.getElementById('myFile');
					objFile.value = '';
					return;
				}
				if (file.size > 1024 * 1024) {
					fileSize = (file.size / (1024 * 1024)).toFixed(2) + 'MB';
				} else if (file.size > 1024) {
					fileSize = (file.size / 1024).toFixed(2) + 'KB';
				}
				var fileType = 0;
				if (file.name.indexOf('.xsl') !== -1 || file.name.indexOf('.xslx') !== -1 || file.name.indexOf('.xlsx') !==
					-1 || file.name.indexOf('.xls') !== -1) {
					fileType = 3; // excel
				}
				console.log(fileType, 'fileType---');
				if (fileType != 3) {
					// 上传完成后清空
					objFile = document.getElementById('myFile');
					objFile.value = '';
					that.$message.error('文件格式不正确，支持xls或xlsx格式，文件大小不超过10M');
					that.loading.close();
					return;
				}
				var obj = {
					fileName: file.name,
					fileSize: fileSize,
					// fileType: fileType,
					fileUrl: '',
					percent: 50
				};
				console.log('添加成功', that.fileData);
				const url = that.fileUrl;
				// setTimeout(function() {
				// 	obj.percent = 50;
				// }, 500)
				that.fileData.push(obj);
				that.isPlan = true;
				that.$api.uploadExport(
					url,
					file,
					function(res) {
						console.log('添加成功-----------', res);
						if (res.code === 200) {
							obj.percent = 100;
							that.verifyData = res.data;
							that.oneCk = false;
						} else {
							console.log('错误', res.msg);
							that.$message.error(res.msg ? res.msg : '添加失败');
						}
						// 上传完成后清空
						objFile = document.getElementById('myFile');
						objFile.value = '';
					},
					function(err) {
						console.log(err);
						// that.$message.error(err.msg);
						// 上传完成后清空
						objFile = document.getElementById('myFile');
						objFile.value = '';
					}
				);
			},
			// 下载导入模板
			downloadBtn() {
				this.$emit('downloadBtn', 'index');
			},
			// 重新上传
			oneBtn() {
				const that = this;
				if (that.isTab === 2) {
					that.isTab = 1;
					that.fileData = [];
					that.isPlan = false;
				} else {
					that.isError = false;
					that.fileData = [];
					that.isPlan = false;
					that.oneCk = true;
				}
				clearTimeout(that.timerPlan);
				clearTimeout(that.timerVerify);
			},
			// 上传
			threeBtn() {
				const that = this;
				if (that.threCk) {
					return;
				}
				that.threCk = true;
				if (that.tableData && that.tableData.length > 0) {
					that.postUpload(that.Daoru_url);
					that.threCk = false;
				} else {
					that.$message.error('无正确数据，请重新上传');
					return;
				}
			},
			postUpload(url) {
				const that = this;
				console.log(url, 'url**-------******');
				// that.tableData
				var obj = that.tableData;
				that.$api.http(
					url, 'POST', obj,
					function(res) {
						console.log('上传成功', res);
						if (res.code === 200) {
							that.isTab = 3;
						} else {
							that.$message.error(res.msg);
						}
					},
					function(err) {
						console.log('上传失败', err);
						// that.$message.error(err.msg);
					}
				);
			},
			// 数据预览
			twoBtn() {
				const that = this;
				console.log('---type', that.fileType);
				if (!that.fileData || that.fileData.length == 0) {
					that.$message.error('请上传文件');
					return;
				}
				console.log(that.verifyData);
				if (that.verifyData && that.verifyData.successNum && that.verifyData.successNum == 0) {
					that.$message.error('无正确数据，请重新上传');
				}
				console.log(that.verifyData, 'that.verifyData*********');
				if (that.fileType == 3) {
					that.verifyData.dataInfos.map(a => {
						that.tableDataJD.push({
							name: a
						});
					});
				} else if (that.fileType == 4) {
					that.verifyData.dataInfos.map(a => {
						that.tableDataZT.push({
							name: a
						});
					});
				}
				that.isTab = 0.5;
				that.timerPlan = setTimeout(function() {
					that.planNum = 100;
				}, 500);
				that.timerVerify = setTimeout(function() {
					console.log('数据', that.isTab, that.verifyData);
					that.tableData = [];
					if (that.verifyData.errorNum == 0 || !that.verifyData.infos || that.verifyData.infos.length ==
						0) {
						that.tableData = that.verifyData.dataInfos;
						that.isTab = 2;
					} else {
						that.isTab = 1;
						that.isError = true;
					}
				}, 1000);

			},
			twoBtnYl() {
				const that = this;
				clearTimeout(that.timerPlan);
				clearTimeout(that.timerVerify);
				if (that.twoCk) {
					return;
				}
				that.twoCk = true;
				if (that.verifyData.dataInfos && that.verifyData.dataInfos.length > 0) {
					console.log('---type', that.fileType);
					that.tableData = that.verifyData.dataInfos;
					that.isTab = 2;
					that.twoCk = false;
				} else {
					that.$message.error('无正确数据，请重新上传');
					that.twoCk = false;
					return;
				}
			},
			// 关闭
			handleClose() {
				this.$emit('handleClose', 'index');
			}
		}
	};
</script>

<style scoped>
	.ptzs {
		color: #2470f8;
	}

	/* file-list */
	.file-list {
		width: 50%;
		height: auto;
	}

	.file-list li {
		width: 100%;
		height: auto;
		margin: 10px 0;
	}

	.file-list li:last-child {
		margin-bottom: 0;
	}

	.file-i01 {
		width: 100%;
		height: auto;
		margin-bottom: 10px;
		padding-right: 5px;
	}

	.file-size,
	.file-right {
		width: calc(100% / 3);
	}

	.file-name {
		flex: 1
	}

	.file-name img {
		width: 18px;
		height: 22px;
		display: block;
		margin-right: 10px;
	}

	.file-name span {
		font-size: 14px;
		color: #999;
		width: calc(100% - 30px);
		display: block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.file-size {
		font-size: 14px;
		color: #999;
		text-align: center;
	}

	.file-right span {
		font-size: 16px;
		color: #666;
		padding-left: 20px;
	}

	.file-btn button {
		font-size: 14px;
		color: #2470f8;
		width: 50px;
		border-right: 1px solid #999;
	}

	.file-btn button:last-child {
		border-right: none;
		color: #666;
	}

	.box3-txt h4 {
		width: 100%;
		text-align: center;
		color: #333;
		font-size: 18px;
		padding-bottom: 30px;
	}

	.box3-txt p {
		text-align: center;
		color: #999;
		font-size: 14px;
	}

	.box3 {
		width: 660px;
		margin: 0 auto;
	}

	/* box3 */
	.fen_box .ptform_input {
		width: 120px;
	}

	.fen_box .ptForm {
		margin-top: 0;
	}

	.box2_tis p i {
		color: #ff0000;
	}

	.box2_tis p {
		color: #333;
		margin-right: 50px;
	}

	.box2_tis p span {
		color: #999;
	}

	/* box2_tis */
	.box1_yc p {
		color: #666666;
		padding-top: 10px;
	}

	.box1_yc b {
		font-size: 14px;
		color: #666666;
		display: block;
		margin-bottom: 5px;
	}

	.box1_yc {
		margin-top: 15px;
		border-radius: 4px;
		border: dashed 1px #eceeef;
		padding: 20px;
		height: 280px;
		background-color: #f7f8f9;
	}

	.box1_you b span {
		font-weight: normal;
		color: #333333;
	}

	.box1_you b:last-child {
		color: #ff2a2a;
		padding-top: 10px;
	}

	.box1_you b {
		font-size: 14px;
		color: #33ac72;
		display: block;
	}

	.box1_you {
		flex: 1;
		padding-left: 28px;
	}

	.box1_icon_left img {
		width: 42px;
		height: 42px;
	}

	.box1_icon_left {
		width: 100px;
		height: 100%;
		background-color: #f7f8f9;
		border-radius: 4px;
		border-right: solid 1px #eceeef;
	}

	.box1_icon {
		height: 100px;
		border-radius: 4px;
		border: solid 1px #eceeef;
	}

	/* box1_icon */
	/* preview-box */
	.preview-box {
		width: 100%;
		padding: 0 30px;
		height: 400px;
		overflow: hidden;
		box-sizing: border-box;
	}

	.prew-tis {
		/* width: 87%; */
		height: auto;
		background: #f7f8f9;
		border-radius: 4px;
		border: 1px dashed #d9d9d9;
		padding: 10px 20px;
		margin-bottom: 15px;
	}

	.pTis-txt {
		width: 100%;
		font-size: 14px;
		color: #999;
		padding: 5px 0;
	}

	.pTis-txt span {
		font-size: 20px;
		font-weight: bold;
		color: #666;
		padding-right: 5px;
	}

	.box1_fg {
		/* width: 78%; */
		background-color: #f7f8f9;
		border-radius: 4px;
		border: 1px dashed #d9d9d9;
		padding: 28px 56px;
	}

	.box1_fg p {
		font-size: 14px;
		color: #808080;
		text-align: center;
	}

	.box1_fg b {
		font-size: 18px;
		color: #666666;
		text-align: center;
		display: block;
		padding-bottom: 14px;
	}

	.box1_fg>img {
		width: 88px;
		height: 60px;
		margin: 0 auto 20px auto;
	}

	.head_li.on::after {
		content: '';
		width: 290px;
		height: 1px;
		background-color: #2470f8;
		position: absolute;
		left: -113px;
		-webkit-transform: translateX(-50%);
		/* transform: translateX(-50%); */
		top: 9px;
		margin-top: 0;
		z-index: 0;
	}

	.head_li:first-child.on::after {
		width: 0;
	}

	.head_li span {
		display: block;
		text-align: center;
		font-size: 14px;
		color: #666666;
		padding-top: 10px;
	}

	.head_li i {
		width: 18px;
		height: 18px;
		display: block;
		margin: 0 auto;
		background: url('../image/img/rqw.png') no-repeat left top;
		background-size: 100% 100%;
		position: relative;
		z-index: 1;
	}

	.head_li {
		position: relative;
		z-index: 1;
	}

	.head_li.on i {
		background: url('../image/img/rq.png') no-repeat left top;
		background-size: 100% 100%;
	}

	.head_li.on span {
		color: #2470f8;
		font-weight: bold;
	}

	.head_box::after {
		content: '';
		width: 90%;
		height: 1px;
		background-color: #f7f8f9;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 9px;
		margin-top: 0;
		z-index: 0;
	}

	.head_box {
		width: 640px;
		position: relative;
		margin: 30px auto 0 auto;
	}
</style>
