<template>
	<div class="flexFloat" style="width:100%">
		<div class="dataPaddingLeft" style="width: calc( 50% - 8px )">
			<el-date-picker
			      v-model="startTimeVal"
			      type="date"
				  clearable
				  :value-format="alueFormat"
				  @change="changeStart"
				  :placeholder="startTxt" >
			    </el-date-picker>
		</div>
		<span class="ptfenGe"></span>
		<div class="dataPaddingLeft" style="width: calc( 50% - 8px )">
			<el-date-picker
			      v-model="endTimeVal"
			      type="date"
				  clearable
				  :value-format="formatType"
				  @change="changeEnd"
			      :placeholder="endTxt">
			    </el-date-picker>
		</div>
	</div>
	
	<!-- 使用  <data-time ref="dateTimeRef" @getStart="(e)=>startTime=e?e:''"  @getEnd="(e)=>endTime=e?e:''"></data-time> -->
</template>

<script>
export default {
	props: {
		alueFormat: {
			type: String,
			default: 'yyyy-MM-dd'
		},
		startTxt: {
			type: String,
			default: '选择开始日期'
		},
		endTxt: {
			type: String,
			default: '选择结束日期'
		},
	},
	data() {
		return {
			startTimeVal:'',
			endTimeVal:''
		};
	},
	watch:{
		alueFormat:{
			handler:function(newVal,oldVal){
				this.formatType=newVal
			},
			immediate:true
		},
	},
	methods: {
		reset(){
			this.startTimeVal='';
			this.endTimeVal='';
		},
		changeStart(e){
			this.$emit('getStart',e)
			this.$nextTick(()=>{
				this.$emit('dateChange')
			})
		},
		changeEnd(e){
			this.$emit('getEnd',e)
			this.$nextTick(()=>{
				this.$emit('dateChange')
			})
		}
	}
};
</script>

<style scoped>
	>>>.el-icon-date{
		display: none  !important;
	}
	
	.dataPaddingLeft >>>.el-input__inner{
		    padding-left: 10px !important;
	}
	
	.ptfenGe{
		width: 6px;
		height: 1px;
		background-color: #606266;
		display: block;
	}
</style>