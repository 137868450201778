import http from './http';
const excel = (fileName) => {
    // window.open(`${process.env.VUE_APP_BASE_API}${fileName}`);
	var fileUrl=`${process.env.VUE_APP_BASE_API}/zhongtaiApi/common/download?delete=false&fileName=${fileName}`;
	fileUrl=encodeURI(fileUrl);
	console.log('导出地址',fileName)
	window.open(fileUrl);
	//  window.open(fileUrl);
	// return http({
	// 	url: 'common/download?delete=false&fileName='+fileName,
	// 	data: {},
	// 	method: 'GET',
	// }).then(res => {
	// 	console.log('下载结果',res);
	// 	 // window.open(`${process.env.VUE_APP_BASE_API}${fileName}`);
	// }).catch(err => {
	// 	console.log(err);
	// });
	
};

export default {
    excel,
};
