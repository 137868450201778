export default [
//     {
//     path: '/testindex',
//     name: '测试',
//     meta: {
//         needLogin: true
//     },
//     component: () => import('../../views/test/index.vue')
// },
];
