<template>
			<div class="flex wrap">
				<div class="ptfile_img zhan" v-for="(item,index) in files" :key="index" style="width:100px;height: 100px;">
					<el-image v-if="item.progress == 100 && fileType == 1" class="img_bai" style="width:100px;height: 100px;" :src="item.fileUrl" fit="cover" :preview-src-list="srcList"></el-image>
					<video v-if="item.progress == 100 && fileType == 2" :src="item.fileUrl" controls class="img_bai"
						style="object-fit: contain;"></video>
					<div class="uploadStyle zhan" v-if="item.progress < 100">
						<img src="../image/img/nob.png" class="img_bai" />
						<div class="uploadStyle-plan flexCenter">
							<div style="width: 100%;">
								<span style="text-align: center;width: 100%;display: block;">正在上传...</span>
								<el-progress :percentage="item.progress"></el-progress>
							</div>
						</div>
					</div>
					<!-- del -->
					<img  src="../image/img/xaa.png" class="ptfile_imgDel shou"
						@click="delFile(index,item.progress)" />
				</div>
				<div class="ptfile_img zhan" v-if="files.length<fuNum">
					<img v-if="fileType == 1" src="../image/img/add.png" class="img_bai" />
					<img v-else-if="fileType == 2" src="../image/img/ta.png" class="img_bai" />
					<input type="file" class="fileInput" ref="fileVideo" multiple="multiple" :accept="accept"
						@change="addUploadVideo" />
				</div>
			</div>
</template>

<script>
	export default {
		name: 'fuJianFile',
		data() {
			return {
				files: [],
				backFiles: [],
				catchDataVideo: [],
				chunkSize: 1024 * 1024,
			};
		},
		props: {
			accept: {
				type: String,
				default: ''
			},
			// 1.图片 2.视频
			fileType: {
				type: Number,
				default: 0
			},
			srcList: {
				type: Array,
				default: () => []
			},
			fuNum: {
				type: Number,
				default: 9
			},
			filesList: {
				type: Array,
				default: () => []
			},
			isDuan: {
				type: Boolean,
				default: true
			},
			MaxSize:  {
				type: Number,
				default: 900
			},
		},
		watch: {
			filesList: {
				handler: function(newValue, oldValue) {
					const that = this;
					console.log('=======', newValue, oldValue);
					that.backFiles = newValue ? [...newValue] : [];
					// 处理回显扩展名
					if (newValue.length > 0) {
						that.files = newValue;
						that.files = that.files.map((item, index) => {
							that.srcList.push(item.fileUrl)
							return {
								progress: 100,
								...item
							};
						});
						console.log(that.files);
					}
				},
				immediate: true,
				deep: true
			}
		},
		mounted() {
			const that = this;
			console.log(that.MaxSize, 'MaxSize------------')
		},
		methods: {
			delFile(index,progress) {
				if(progress <100) {
					this.files[index].status = 'paused';
				}
				this.files.splice(index, 1);
				this.backFiles.splice(index, 1);
				this.$emit('uploadFinish', this.backFiles);
			},
			addUploadVideo() {
				// var docObj = document.getElementById('fileVideo');
				  var docObj = this.$refs.fileVideo;
				this.addRow(docObj.files)
			},
			addRow(files) {
				const that = this
				var number = '';
				number = Number(that.fuNum) - (that.files.length > 0 ? that.files.length : 0);
				if (number < files.length) {
					that.$message.warning('超过上传限制，只能上传' + that.fuNum + '个');
					return;
				}
				
				var number = '';
				for (let i = 0; i < files.length; i++) {
					let identifier = Number(Math.random().toString().substr(5, 10) + Date.now()).toString(36)
					const file = files[i];
					// if (file.size < that.MaxSize) {
					// 	that.$message.warning('超过上传限制，只能上传' + that.MaxSize + 'M');
					// 	return;
					// }
					var totoalSize = (file.size / 1024/1024).toFixed(2)
					if (totoalSize > that.MaxSize) {
						var txt = `文件大小不得大于${that.MaxSize}M`
						that.$message.warning(txt);
						return;
					}
					const fileData = {
						identifier: identifier,
						name: file.name,
						size: file.size,
						status: 'uploading',
						progress: 0,
						chunks: [],
						currentChunk: 0,
						timer: ''
					};
					that.files.push(fileData)
					console.log(that.files, 'that.files*------')
					that.uploadFile(fileData, file);
				}
			},
			uploadFile(fileData, file) {
				const totalChunks = Math.ceil(file.size / this.chunkSize);
				fileData.totalChunks = totalChunks
				const chunks = [];
				for (let i = 0; i < totalChunks; i++) {
					const start = i * this.chunkSize;
					const end = Math.min(start + this.chunkSize, file.size);
					const chunk = file.slice(start, end);

					chunks.push(chunk);
				}
				fileData.chunks = chunks;
				this.uploadChunks(fileData);
			},
			async uploadChunks(fileData) {
				const {
					chunks,
					currentChunk
				} = fileData;
				const chunk = chunks[currentChunk];
				const url = new URL(process.env.VUE_APP_UPLOADBASE_API + 'common/upload/uploadUrlByPc');
				url.searchParams.append('fileName', fileData.name);
				url.searchParams.append('identifier', fileData.identifier);
				url.searchParams.append('index', currentChunk);

				url.searchParams.append('chunkSize', this.chunkSize);
				url.searchParams.append('totalChunks', fileData.totalChunks);
				url.searchParams.append('totalSize', fileData.size);
				let that = this
				console.log(that.files, 'that.files003-------------------------')
				that.$httpPostNoLog(
					url,
					chunk,
					function(res) {
						console.log(res);
						if (res.code == 200) {
							// 上传成功
							fileData.currentChunk += 1;
							var progress =  parseInt(((fileData.currentChunk / fileData.chunks.length) * 100));
							fileData.timer = setInterval(() => {
								fileData.progress += 1
								if (fileData.progress == progress) {
									clearInterval(fileData.timer)
									if (fileData.currentChunk === fileData.chunks.length) {
										// 所有分片上传完成
										that.statusProgress = 'success'
										fileData.status = 'completed';
										//调佣合并接口
										that.getMergeUrl(fileData)
										var obj = that.$refs.fileVideo;
										obj.value = '';
										//清空上传文件，以免不能上传相同文件
										//	that.$refs.fileVideo.value = ''
									} else if (fileData.status === 'uploading') {
										// 继续上传下一个分片
										that.uploadChunks(fileData);
									}
								}
							}, 10)
							// fileData.progress = parseInt(((fileData.currentChunk / fileData.chunks.length) * 100));
						}
					},
					function(err) {
						that.$refs.fileVideo.value = ''
						// 上传失败
						console.error('上传失败');
					}
				);
			},
			getMergeUrl(fileData) {
				const that = this;
				let url = process.env.VUE_APP_UPLOADBASE_API + 'common/upload/mergeUrl';
				let para = {
					fileName: fileData.name,
					identifier: fileData.identifier
				};
				that.$httpGetNoLog(
					url,
					para,
					function(res) {
						if (res.code == 200) {
							clearInterval(fileData.timer)
							var obj = {
								fileName: fileData.name,
								fileUrl: res.url,
								progress: 100,
								fileSize: fileData.size
							}
							that.backFiles.push(obj)
							var index = ''
							that.files.map((a,aindex) => {
								if(a.identifier == fileData.identifier) {
									index = aindex
								}
							})
							that.files[index] = obj
							if(that.fileType == 1) {
								that.srcList.push(res.url)
							}
							if (that.backFiles.length == that.files.length) {
								that.$emit('uploadFinish', that.backFiles);
							}
						}
					},
					function(err) {
						// 上传失败
						console.error('上传失败');
					}
				);

			},
		}
	};
</script>

<style scoped>
	.ptfile_imgDel {
		right: 0;
		top: 0;
	}

	.uploadStyle {
		width: 100%;
		height: 100%;
	}

	.uploadStyle>img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.uploadStyle-plan {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		padding-left: 20px;
	}

	.ptfile_img {
		/* border-radius: 10px; */
		overflow: hidden;
		margin: 0 10px 10px 0;
		position:relative;
	}
	.ptfile_img  >>>.el-image{
		width: 5rem;
		    height: 5rem;
			border-radius: .4rem;
			overflow: hidden;
	}
	
	.ptfile_imgDel{
		width: 1rem;
		    height: 1rem;
		    position: absolute;
		    right: 0;
		    top: 0;
		    z-index: 2;
	}
</style>