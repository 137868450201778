<template>
	<div class="xiaoXi">
		<el-badge :value="nuberTime" class="item">
			<view></view>
		</el-badge>
	</div>
</template>
<script>
	export default {
		name: 'xiaoXi',
		data() {
			return {
				notNumber: 1,
				nuberTime: null
			};
		},
		mounted(){
			if (this.nuberTime) {
				clearInterval(this.nuberTime);
				this.nuberTime = null;
			}
			this.nuberTime = setInterval(() => {
				if (this.$store.state.user.user) {
					this.getUserNoticeNumber();
				} else {
					clearInterval(this.nuberTime);
					this.nuberTime = null;
				}
			}, 15000);
		},
		methods:{
			getUserNoticeNumber() {
				var that = this;
				that.$api.http(
					'/pc/home/getUserNoticeNumber',
					'GET', {},
					function(res) {
						if (res.code == 200) {
							console.log('--------------')
							that.notNumber = res.data;
						} else {
							that.$message.error(res.msg || '失败');
						}
					},
					function(err) {
						console.log('错误', err);
					}
				);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.xiaoXi {
		position: fixed;
		right: 14.3rem;
		top: .4rem;
		margin: 0;
		z-index: 5;
	}
</style>