// import request from '../utils/request';
import request from '@/utils/http';
export const httpGet=function(url,params,success,fail) {
    return request({
        url: url,
        params: params,
        method: 'GET',
    }).then(res => {
        success(res)
    }).catch(err => {
        console.log(err);
        fail(err)
    });
}
export const httpPost=function(url,data,success,fail) {
    return request({
        url: url,
        data: data,
        method: 'POST',
    }).then(res => {
        success(res)
    }).catch(err => {
        console.log(err);
        fail(err)
    });
}
export const httpGetNoLog=function(url,params,success,fail) {
    return request({
        url: url,
        params: params,
        method: 'GET',
		headers: {
			notShowError: true,
			showLoading: false,
			showMsgLoading: false,
			'Content-Type': 'application/json;charset=utf-8'
		},
    }).then(res => {
        success(res)
    }).catch(err => {
        console.log(err);
        fail(err)
    });
}

export const httpPostNoLog=function(url,data,success,fail) {
    return request({
        url: url,
        data: data,
        method: 'POST',
		headers: {
			notShowError: true,
			showLoading: false,
			showMsgLoading: false,
			 'Content-Type': 'application/json;charset=utf-8'
		},
    }).then(res => {
        success(res)
    }).catch(err => {
        console.log(err);
        fail(err)
    });
}
/**
 * 通用接口请求
 *
 * @param {*} data
 * @returns
 */
export function HttpRequest(url, method, data, success, fail) {
	return request({
		url: url,
		params: data,
		method: method
	}).then(res => {
		success(res);
	}).catch(err => {
		fail(err);
	});
}
export function HttpPut(url, data, success, fail) {
	return request({
		url: url,
		data: data,
		method: "PUT"
	}).then(res => {
		success(res);
	}).catch(err => {
		fail(err);
	});
}
export function HttpDelete(url, data, success, fail) {
	return request({
		url: url,
		params: data,
		method: "DELETE"
	}).then(res => {
		success(res);
	}).catch(err => {
		fail(err);
	});
}
export function HttpUpload(files, success, fail) {
	let formdata = new FormData();
    formdata.append('file', files);
    return request({
        url: 'common/upload',
        data: formdata,
        method: 'POST',
        headers: {
            notShowError: true,
            showLoading: false
        }
    }).then(res => {
        success(res);
    }).catch(err => {
        fail(err);
    });
}
