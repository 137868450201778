import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import store from '../store';

const getUser = () => {
    const userStr = Cookies.get('fzjt_user');
    const key = Cookies.get('fzjt_provider');
    if (userStr && key) {
        const decodeStr = CryptoJS.AES.decrypt(userStr, key).toString(CryptoJS.enc.Utf8);
        if (decodeStr) {
            store.dispatch('user/setUser', JSON.parse(decodeStr));
        }
    }
};

const getToken = () => {
    const token = Cookies.get('fzjt_token');
    if (token) {
        store.dispatch('user/setToken', token);
    }
};

const getLoginData = () => {
    const loginData = Cookies.get('fzjt_loginData');
    if (loginData) {
        store.dispatch('user/setLoginData', loginData);
    }
};

export const initData = () => {
    getUser();
    getToken();
	getLoginData();
};
