import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const letterArr = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

/**
 * 生成key
 */
const generateKey = () => {
    let key = '';
    for (let i = 0; i < 32; i++) {
        const index = Math.floor(Math.random() * 36);
        key += letterArr[index];
    }
    return key;
};

const state = {
    // 用户信息
    user: null,
    // token
    token: null,
    userId: 0,
	homeUrl:'',
	loginData:null,
	selectRole:null,
	isUpdate:false
};

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;
        Cookies.set('fzjt_token', token);
    },
    DELETE_TOKEN: (state) => {
        state.token = null;
        Cookies.remove('fzjt_token');
    },
	SET_LOGINDATA: (state, ldata) => {
        state.loginData = ldata;
        Cookies.set('fzjt_loginData', ldata);
    },
    SET_USER: (state, user) => {
        state.user = user;
        state.userId = user.Id;
        const key = generateKey();
        Cookies.set('fzjt_provider', key);
        const userStr = CryptoJS.AES.encrypt(JSON.stringify(user), key);
        Cookies.set('fzjt_user', userStr);
    },
    DELETE_USER: (state) => {
        state.user = null;
        Cookies.remove('fzjt_user');
    },
    LOGOUT: (state) => {
        state.token = null;
        state.user = null;
        Cookies.remove('fzjt_token');
        Cookies.remove('fzjt_user');
		Cookies.remove('fzjt_menuRecord');
    },
	CELLOGDATA: (state) => {
        state.loginData = null;
        Cookies.remove('fzjt_loginData');
    },
};

const actions = {
    /**
     * 获取token
     *
     * @param {*} {
     *         commit,
     *         state
     *     }
     * @param {*} data
     */
    setToken({
        commit
    }, data) {
        commit('SET_TOKEN', data);
    },
    /**
     * 删除token
     *
     * @param {*} {
     *         commit
     *     }
     */
    deleteToken({
        commit
    }) {
        commit('DELETE_TOKEN');
    },
    /**
     * 设置用户信息
     *
     * @param {*} { commit }
     * @param {*} data
     */
    setUser({
        commit
    }, data) {
        commit('SET_USER', data);
    },
	setLoginData({
        commit
    }, data) {
        commit('SET_LOGINDATA', data);
    },
	celLogData({
	    commit
	}) {
	    commit('CELLOGDATA');
	},
    /**
     * 删除用户
     *
     * @param {*} {
     *         commit
     *     }
     */
    deleteUser({
        commit
    }) {
        commit('DELETE_USER');
    },
    /**
     * 登出
     *
     * @param {*} {
     *         commit
     *     }
     */
    logout({
        commit
    }) {
        commit('LOGOUT');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
