<template>
    <div class="ptend_gao">
        <div class=" ptend_div">
            <img src="../../../image/wu_b.png" />
            <p>{{ noTitle }}</p>
            <span>{{ noTxt }}</span>
        </div>
    </div>
</template>

<script>
// 无内容时显示的组件
export default {
    name: 'noContent',
    props: {
        noTitle: {
            type: String,
            default: '没有找到匹配的结果'
        },
        noTxt: {
            type: String,
            default: '建议您修改关键词重新搜索'
        }
    },
    data() {
        return {};
    },
    methods: {}
};
</script>

<style scoped lang="scss"></style>
