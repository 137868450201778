import http from '../utils/http';
export default {
	/**
	 * 获取API
	 *
	 * @param {*} data
	 * @returns
	 */
	http(url, method, data, success, fail) {
		return http({
			url: url,
			data: data,
			method: method
		}).then(res => {
			success(res);
		}).catch(err => {
			console.log(err);
			fail(err);
		});
	},
	httpNotLoading(url, method, data, success, fail) {
		return http({
			url: url,
			data: data,
			method: method,
			headers: { showLoading: false }
		}).then(res => {
			success(res);
		}).catch(err => {
			console.log(err);
			fail(err);
		});
	},
	httpGet(url, params, success, fail) {
		return http({
			url: url,
			params: params,
			method: 'get',
			headers: { loading: false }
		}).then(res => {
			success(res);
		}).catch(err => {
			console.log(err);
			fail(err);
		});
	},
	/**
	 * 上传图片
	 *
	 * @param {*} file
	 */
	uploadPicture(file, success, fail) {
		console.log('上传了');
	    const formData = new FormData();
	    formData.append('file', file);
	    return http({
	        url: 'common/upload',
	        method: 'POST',
	        headers: {
				notShowError: true,
				showLoading: false,
				showMsgLoading: false,
	            'Content-Type': 'multipart/form-data'
	        },
	        data: formData
	    }).then(res => {
	        success(res);
	    }).catch(err => {
	        fail(err);
	    });
	},

	/**
	 * 上传图片
	 *
	 * @param {*} file
	 */
	uploadExport(url, file, success, fail) {
		console.log('上传了');
	    const formData = new FormData();
	    formData.append('file', file);
	    return http({
	        url: url,
	        method: 'POST',
	        headers: {
				notShowError: true,
				showLoading: false,
				showMsgLoading: false,
	            'Content-Type': 'multipart/form-data'
	        },
	        data: formData
	    }).then(res => {
	        success(res);
	    }).catch(err => {
	        fail(err);
	    });
	}

};
